<template>
  <div  class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          name="save-button"
          classProp="primary"
          :isDisabled="disableSaveButton"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
        <VasionButton
          id="cancel-button"
          class="last-btn"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <AutomationEngineSharedHeader
        ref="header"
        :nameLabel="'IMPORT NAME'"
        @permissions="showMainSection = false"
        @general-automation="showMainSection = true"
        @dirty="setIsDirty()"
      />

      <!-- Build Data Import -->
      <div v-if="showMainSection" id="build-data-import" class="collapsable-section">
        <h2 class="subheader">
          Build Data Import
        </h2>

        <VasionButton
          name="collapse-section"
          class="collapse-section"
          :icon="showBuildImport ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="toggleCollapsableSection('build-data-import')"
        />

        <div v-show="showBuildImport">
          <div class="row">
            <VasionDropList
              class="row-field"
              v-slot="slotItem"
              v-model="newImportFromFolder"
              :required="true"
              :dataArray="importFromValues"
              :title="'IMPORT FROM'"
              :type="'plain-list'"
              :valueName="'name'"
              displayName="name"
              width="396"
              @input="setIsDirty();"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            
            <div
              v-if="isVasionFolder || isCloudStorage"
              class="row continue"
            >
              <VasionInput
                id="import-to"
                v-model="newSelectedFolder"
                class="row-field browse-input"
                title="FOLDER"
                inputType="top-white"
                name="import-to"
                placeholder="Select Folder..."
                :required="true"
                :width="'396'"
              />
              <VasionButton
                id="import-to-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('importFrom')"
              >
                Browse
              </VasionButton>
            </div>
            <div v-else-if="isExternalStorage">
              <VasionInput
                v-model="newSelectedFolderText"
                class="row-field browse-input"
                title="FOLDER"
                inputType="top-white"
                name="import-to"
                placeholder="Enter Folder..."
                :required="true"
                :width="'396'"
                @input="setIsDirty"
              />
            </div>
          </div>

          <div class="row">
            <div class="radio-container">
              <md-radio
                id="new-table-radio"
                v-model="newOrExistingTable"
                value="new"
                @change="setIsDirty()"
              >
                Import to New Table
              </md-radio>
            </div>
            <div class="radio-container">
              <md-radio
                id="existing-table-radio"
                v-model="newOrExistingTable"
                value="existing"
                @change="setIsDirty()"
              >
                Import to Existing Table
              </md-radio>
            </div>
          </div>
          
          <!-- 
            For now, I'm ommiting the existing table options. 
            Might want to edit the data so that it uses the same tags when adding in the existing table option. 
          -->
          <div>
            <div class="row">
              <VasionInput
                id="new-table-name"
                v-model="newTableName"
                class="row-field"
                title="NEW TABLE NAME"
                inputType="top-white"
                name="new-table-name"
                placeholder="Enter Name..."
                :isDisabled="newOrExistingTable === 'existing'"
                :required="true"
                :width="'396'"
                @input="setIsDirty()"
              />
            </div>

            <div class="row">

              <VasionDropList
                class="row-field"
                v-slot="slotItem"
                v-model="newFileFormat"
                :required="true"
                :dataArray="fileFormatValues"
                :isDisabled="newOrExistingTable === 'existing'"
                :title="'FILE FORMAT'"
                :type="'plain-list'"
                :valueName="'name'"
                displayName="name"
                width="396"
                @input="setIsDirty()"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionInput
                id="new-delimiter"
                v-show="newFileFormat.name === 'CSV'"
                v-model="newDelimiter"
                class="row-field"
                title="DELIMITER"
                inputType="top-white"
                name="new-delimiter"
                placeholder="Enter Delimiter..."
                :isDisabled="newOrExistingTable === 'existing'"
                :required="true"
                :width="'396'"
                @input="setIsDirty()"
              />
            </div>

            <div class="row" v-if="newOrExistingTable === 'new'">
              <VasionInput
                id="sample-file"
                v-model="newSampleFile"
                class="row-field browse-input"
                title="SAMPLE FILE"
                inputType="top-white"
                name="sample-file"
                placeholder="Select File..."
                :required="false"
                :width="'396'"
              />
              <VasionButton
                id="sample-file-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseForSampleFileDialog(); setIsDirty();"
              >
                Browse
              </VasionButton>
            </div>
            <div class="row" v-if="newOrExistingTable === 'new'">
              <VasionButton
                id="configure-mapping-button"
                :isDisabled="!(isEditMode || (newDelimiter !== '' && uploadedCSVFileString !== ''))"
                :classProp="'secondary-grey'"
                @vasionButtonClicked="toggleConfigureMappingModal()"
              >
                {{ mappingButtonText }}
              </VasionButton>
            </div>
          </div>

          <div class="row">
            <div class="radio-container">
              <md-radio
                id="delete-table-radio"
                v-model="deleteAppendOrUpdateRows"
                value="delete"
                @change="setIsDirty()"
              >
                Delete All Rows in Destination Table
              </md-radio>
            </div>
            <div class="radio-container">
              <md-radio
                id="append-table-radio"
                v-model="deleteAppendOrUpdateRows"
                value="append"
                @change="setIsDirty()"
              >
                Append Rows to Destination Table
              </md-radio>
            </div>
            <div class="radio-container">
              <md-radio
                id="replace-table-radio"
                v-model="deleteAppendOrUpdateRows"
                value="update"
                @change="setIsDirty()"
              >
                Update Existing Rows and Append New Rows in Destination Table
              </md-radio>
            </div>
          </div>

          <div class="row">
            <VasionCheckbox
              id="move-completed-documents-checkbox"
              name="move-completed-documents-checkbox"
              class="import-checkbox"
              :isDisabled="isVasionFolder"
              :checked="moveToCompletedFolder"
              @change="toggleCheckbox('moveToCompletedFolder')"
            >
              Move to Completed Folder
            </VasionCheckbox>
            <VasionCheckbox
              id="deleteDocumentFileImport"
              name="deleteDocumentFileImport"
              class="import-checkbox"
              :checked="deleteDocumentFileImport"
              @change="toggleCheckbox('deleteDocumentFileImport')"
            >
              Delete File Upon Import
            </VasionCheckbox>
          </div>

          <!-- TODO: update v-if when Vasion Folder is supported. Along with isDisabled on checkbox -->
          <div
            v-if="moveToCompletedFolder && !isVasionFolder"
            class="row"
          >
            <div
              v-if="isVasionFolder || isCloudStorage"
              class="row continue"
            >
              <VasionInput
                id="completed-documents-folder"
                v-model="selectedCompletedFolder"
                title="COMPLETED FOLDER"
                :readonly="true"
                inputType="top-white"
                name="completed-documents-folder"
                placeholder="Select Folder..."
                :required="true"
                :width="'396'"
                @input="isDirty = true"
              />
              <VasionButton
                id="completed-documents-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('completedFolder')"
              >
                Browse
              </VasionButton>
            </div>
            <div v-else-if="isExternalStorage">
              <VasionInput
                v-model="selectedCompletedFolderText"
                class="row-field browse-input"
                title="COMPLETED FOLDER"
                inputType="top-white"
                name="import-to"
                placeholder="Enter Folder..."
                :required="true"
                :width="'396'"
                @input="setIsDirty"
              />
            </div>
          </div>

          <div class="row">
            <VasionCheckbox
              id="send-report-via-email"
              name="send-report-via-email"
              class="import-checkbox"
              :checked="sendReportViaEmail"
              @change="toggleCheckbox('sendReportViaEmail')"
            >
              Send Report via Email
            </VasionCheckbox>
          </div>

          <div v-if="sendReportViaEmail" >
            <VasionButton
              id="addCCRecipient"
              :classProp="'secondary-grey'"
              class="row"
              @vasionButtonClicked="addReportEmail"
            >
              Add Report Email
            </VasionButton>
            <div
              v-for="(rEmail, i) in reportEmails"
              :key="i"
              class="row"
            >
              <VasionInput
                v-model="rEmail.value"
                id="email-address"
                class="row-field"
                title="EMAIL ADDRESS"
                inputType="top-white"
                name="email-address"
                :specialType="'email'"
                :placeholder="'Enter Email...'"
                @input="setIsDirty"
              />
              <VasionButton
                class="trash-can-padding"
                title="Remove Field"
                :isDense="true"
                :icon="'delete'"
                @vasionButtonClicked="deleteReportEmail(i)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <VasionFileUploadDialog
      :serverOptions="serverOptions"
      :show.sync="sampleFileDialog"
      :acceptedFileTypes="['.csv']"
      :filePondLabel="filePondLabel"
      @allFilesUploaded="toggleBrowseForSampleFileDialog()"
    />

    <AutomationCSVMapping
      :delimiter="newDelimiter"
      :readOnlyMapping="mappingConfiguration"
      :show="showConfigureMappingModal"
      :uploadedCSV="uploadedCSVFileString"
      :allowUpdate="deleteAppendOrUpdateRows == 'update'"
      @close="toggleConfigureMappingModal"
      @mappingConfiguration="setMappingConfiguration"
    />

    <md-dialog :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <VasionFolderSelect
        okButtonText="Select"
        :title="browseModalTitle"
        :showOnlyDataAutomation="newImportFromFolder.name === 'Cloud Storage'"
        @cancelButtonClick="toggleBrowseFolderDialog(lastClickedBrowseButton)"
        @okButtonClick="folderDialogOK"
      />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="cancelLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="import-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>


</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationCSVMapping from '@/views/admin/automationEngine/AutomationCSVMapping';
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { toBase64 } from '@/store/helperModules/storage.module'
import VasionButton from '../../../components/shared/VasionButton.vue';

export default {
  name: 'EditDataImport',
  components: {
    AutomationCSVMapping,
    AutomationEngineSharedHeader,
    Loading,
    VasionButton,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      completedFolderDriveType: {},
      completedFolderID: null,
      deleteDocumentFileImport: false,
      deleteAppendOrUpdateRows: 'delete',
      fileFormatValues: [
        {name: 'CSV'},
        // {name: 'Flat File'},
        // {name: 'Excel'},
      ],
      filePondLabel: `<span class="filepond-drag">Drag CSV File Here</span>
                <br/><br/>
                <span class="filepond-label-action">or click to upload your CSV File</span>`,
      folderId: null,
      importFromValues: [
        {name: 'Vasion Folder'},
        {name: 'Cloud Storage'},
        {name: 'External Source'},
      ],
      dataImportId: 0,
      isDirty: false,
      isEditMode: false,
      isLoading: false,
      lastClickedBrowseButton: '',
      loaderBackgroundColor,
      loaderColor,
      mappingConfiguration: [],
      moveToCompletedFolder: false,
      newDelimiter: ',',
      newFileFormat: '',
      newImportFromFolder: {},
      newOrExistingTable: 'new',
      newSampleFile: '',
      newSelectedFolder: '',
      newSelectedFolderText: '',
      newTableName: '',
      reportEmails: [],
      sampleFileDialog: false,
      saveLocked: false,
      schedulerTitle: '',
      selectedDriveType: {},
      selectedCompletedFolder: '',
      selectedCompletedFolderText: '',
      sendReportViaEmail: false,
      serverOptions: {
        process: this.processHandler,
      },
      showBrowseFoldersDialog: false,
      showBuildImport: true,
      showConfigureMappingModal: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      uploadedCSVFileString: '',
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    browseModalTitle() {
      if (this.lastClickedBrowseButton === 'importFrom') return 'Select Import Folder'
      if (this.lastClickedBrowseButton === 'completedFolder') return 'Select Completed Folder'
      return ''
    },
    disableSaveButton() { return this.saveLocked || !this.isDirty },
    isCloudStorage() { return this.newImportFromFolder.name === 'Cloud Storage' },
    isExternalStorage() { return this.newImportFromFolder.name === 'External Source' },
    isVasionFolder() { return this.newImportFromFolder.name === 'Vasion Folder' },
    mappingButtonText() { return this.uploadedCSVFileString === '' ? "View Mapping" : "Configure Mapping" },
    sendReportViaEmailList() {
      let csv_string = ''
      this.reportEmails.forEach((elem, i) => {
        if (i === 0) csv_string += elem.value
        else csv_string += `,${elem.value}`
      })
      return csv_string
    },
  },
  watch: {
    newFileFormat: function () {
      if (this.newFileFormat.name === 'CSV' && !this.isLoading)
        this.newDelimiter = ','
    },
    newImportFromFolder: function(newVal, oldVal) {
      if (newVal.name !== oldVal.name && oldVal?.name) {
        this.newSelectedFolder = ''
        this.completedFolderID = ''
        this.selectedCompletedFolder = ''
      }
    },
  },
  async created() {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('automationEngine/clearActiveScheduler'),
    ])

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getDataImportDetails', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        await this.setImportValues()
      }
    }
    if (!this.newImportFromFolder.name) {
      this.newImportFromFolder = { name: 'Vasion Folder' }
    }
    await this.setHeaderDetails(this.dataImportId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.dataImportId && this.dataImportId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Data Import'

    this.isLoading = false
    this.isDirty = false
  },
  methods: {
    addReportEmail() {
      this.reportEmails.push({value: ''})
    },
    cancel() { this.$router.push({ name: 'DataImport' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    deleteReportEmail(i) {
      this.reportEmails.splice(i, 1)
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK(payload) {
      this.setIsDirty()
      if (this.lastClickedBrowseButton === 'importFrom') {
        this.folderId = payload.SelectedFolderID
        this.newSelectedFolder = payload.SelectedFolderName
        this.selectedDriveType = payload.SelectedDriveType
      } else if (this.lastClickedBrowseButton === 'completedFolder') {
        this.completedFolderID = payload.SelectedFolderID
        this.selectedCompletedFolder = payload.SelectedFolderName
        this.completedFolderDriveType = payload.SelectedDriveType
      }

      this.toggleBrowseFolderDialog(this.lastClickedBrowseButton)
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      if (file?.name.endsWith('.csv')) {
        this.uploadedCSVFileString = await toBase64(file)
        const base64String = this.uploadedCSVFileString.split('base64,')[1]
        if (!base64String){
          error(true)
          this.snackbarTitle = "ERROR"
          this.snackbarSubTitle = 'Empty CSV can not be used'
          this.showSnackbarBool = true
          return
        }
        progress(true, 0, 1024)
        load(file?.name)
        this.newSampleFile = file?.name
      } else {
        error(true)
        this.showSnackbarBool = true
        this.snackbarImage = false
        this.snackbarTitle = "ERROR"
        this.snackbarSubTitle = "Can only upload files that end in \".csv\"."
        return
      }
      this.removePreviousMapping()
      return {
        abort: () => {
          abort();
        },
      };
    },
    removePreviousMapping() {
      this.mappingConfiguration = []
    },
    async save() {
      this.saveLocked = true
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          CompletedFolderPath: this.isExternalStorage && this.moveToCompletedFolder ? this.selectedCompletedFolderText : null,
          CompletedFolderID: this.isVasionFolder && this.moveToCompletedFolder ? null : null, // TODO: fix when Vasion Storage is supported
          CompletedThirdPartyFolderId: this.isCloudStorage && this.moveToCompletedFolder ? this.completedFolderID : null,
          CompletedEmails: this.reportEmails.length !== 0 ? this.sendReportViaEmailList : null,
          ImportFromFolderPath: this.isExternalStorage ? this.newSelectedFolderText : null,
          ImportFromFolderID: this.isVasionFolder ? this.folderId : null,
          ImportFromStorageConfigId: this.isCloudStorage ? this.selectedDriveType.storageConfigId : null,
          ImportFromThirdPartyFolderId: this.isCloudStorage ? this.folderId : null,
          TableName: this.newTableName,
          TableExists: this.newOrExistingTable === 'existing',
          FileFormat: this.newFileFormat.name,
          Delimiter: this.newDelimiter,
          DeletePreviousData: this.deleteAppendOrUpdateRows === 'delete',
          DeleteImportFile: this.deleteDocumentFileImport,
          ColumnMappings: this.mappingConfiguration,
        }

        let error = await this.$store.dispatch('automationEngine/saveDataImportScheduler', payload);
        if(error === "Table Mismatch") {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'Table already exists, please select "Import to Existing Table"'
          this.snackbarImage = false
          this.saveLocked = false
        } else if (error) {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = error
          this.snackbarImage = false
          this.saveLocked = false
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = 'Import saved successfully.'
          this.snackbarImage = true
          this.saveLocked = false

          this.$router.push({ name: 'DataImport' })
        }
        this.showSnackbarBool = true
      }
    },
    async setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        await header.setValues(details)
      } else {
        await header.setValues()
      }
    },
    async setImportValues() {
      this.dataImportId = this.activeScheduler.runDetails.schedulerID
      if (this.activeScheduler.CompletedFolderPath){
        this.selectedCompletedFolderText = this.activeScheduler.CompletedFolderPath
      }
      else if (this.activeScheduler.CompletedFolderID) {
        this.selectedCompletedFolder = this.activeScheduler.CompletedFolderName
        this.completedFolderID = this.activeScheduler.CompletedFolderID
      }
      else if (this.activeScheduler.CompletedThirdPartyFolderId) {
        this.selectedCompletedFolder = this.activeScheduler.CompletedFolderName
        this.completedFolderID = this.activeScheduler.CompletedThirdPartyFolderId
      }
      if (this.selectedCompletedFolderText || this.selectedCompletedFolder) this.moveToCompletedFolder = true
      if (this.activeScheduler.CompletedEmails) {
        this.sendReportViaEmail = true
        let email_array = this.activeScheduler.CompletedEmails.split(',')
        email_array.forEach(elem => {
          this.reportEmails.push({value: elem})
        })
      }
      this.newSelectedFolderText = this.activeScheduler.ImportFromFolderPath
      this.folderId = this.activeScheduler.ImportFromFolderID
      this.completedFolderDriveType.storageConfigId = this.activeScheduler.ImportFromStorageConfigId
      this.selectedDriveType.storageConfigId = this.activeScheduler.ImportFromStorageConfigId
      this.newImportFromFolder = {
        name: (this.newSelectedFolderText ? 'External Source' : (this.folderId ? 'Vasion Folder' : 'Cloud Storage'))
      }
      this.folderId = this.folderId ? this.folderId : this.activeScheduler.ImportFromThirdPartyFolderId
      this.newSelectedFolder = this.activeScheduler.ImportFromFolderName
      this.newTableName = this.activeScheduler.TableName.replace('Custom_', '')
      this.newOrExistingTable = this.activeScheduler.TableExists ? 'existing' : 'new'
      this.newFileFormat = { name : this.activeScheduler.FileFormat }
      this.newDelimiter = this.activeScheduler.Delimiter
      this.deleteAppendOrUpdateRows = this.activeScheduler.DeletePreviousData ? 'delete' : 'append'
      if (this.deleteAppendOrUpdateRows === 'append' && this.activeScheduler.ColumnMappings.find(m => m.IsKey)) {
        this.deleteAppendOrUpdateRows = 'update'
      }
      this.deleteDocumentFileImport = this.activeScheduler.DeleteImportFile
      this.mappingConfiguration = this.activeScheduler.ColumnMappings
      this.isEditMode = true
      this.showBuildImport = true
    },
    setIsDirty() {
      this.isDirty = true
    },
    setMappingConfiguration(value) {
      this.setIsDirty()
      this.mappingConfiguration = value
    },
    toggleBrowseFolderDialog(button) {
      this.lastClickedBrowseButton = button
      switch(button) {
        case 'importFrom':
        case 'completedFolder':
          this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
          break
        default:
          break
      }
    },
    toggleBrowseForSampleFileDialog() { this.sampleFileDialog = !this.sampleFileDialog },
    toggleConfigureMappingModal() { this.showConfigureMappingModal = !this.showConfigureMappingModal },
    toggleCheckbox(id) {
      // Only allow one of these at maximum to be checked:
      if (id === 'deleteDocumentFileImport') this['moveToCompletedFolder'] = false
      else if (id === 'moveToCompletedFolder') this['deleteDocumentFileImport'] = false

      if (id === 'sendReportViaEmail' && this.reportEmails.length === 0) {
        this.addReportEmail()
      } 

      this[id] = !this[id]
      this.setIsDirty()
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'build-data-import':
          this.showBuildImport = !this.showBuildImport
          break;
        default:
          this.showBuildImport = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let errorText = ''
      this.snackbarTitle = 'ERROR'
      this.snackbarImage = false

      await header.validate()
      if (header.errors !== '') {
        errorText += `${header.errors}`
      }

      if (this.newTableName === '' ||
        this.newTableName.includes('"') ||
        this.newTableName.includes('/') ||
        this.newTableName.includes(':') ||
        this.newTableName.includes('*') ||
        this.newTableName.includes('?') ||
        this.newTableName.includes("'") ||
        this.newTableName.includes('<') ||
        this.newTableName.includes('>') ||
        this.newTableName.includes('|') ||
        this.newTableName.includes('#')
      ) {
        errorText += '- Please provide a proper value for "New Table Name".\n'
      }

      if (this.newFileFormat === '')
        errorText += '- Please provide a value for "File Format".\n'

      if (this.newDelimiter === '')
        errorText += '- Please provide a value for "Delimiter".\n'

      if (!(this.mappingConfiguration && this.mappingConfiguration.length > 0))
        errorText += '- Please provide a list of Mapping Fields using the Sample File.\n'

      if (this.moveToCompletedFolder) {
        if (!this.selectedCompletedFolderText && !this.selectedCompletedFolder)
          errorText += '- Please provide a folder underneath Completed Folder.\n'

        if (this.isCloudStorage && ((this.completedFolderDriveType.storageConfigId !== this.selectedDriveType.storageConfigId) || (this.completedFolderID === this.folderId)))
          errorText += '- Please select a separate folder in COMPLETED FOLDER with the same provider as the IMPORT FROM - FOLDER.\n'

        while(this.newSelectedFolderText[this.newSelectedFolderText.length - 1] === '\\' || this.newSelectedFolderText[this.newSelectedFolderText.legnth -1] === '/'){
          this.newSelectedFolderText = this.newSelectedFolderText.slice(0, this.newSelectedFolderText.length - 1)
        }
        while(this.selectedCompletedFolderText[this.selectedCompletedFolderText.length - 1] === '\\' || this.selectedCompletedFolderText[this.selectedCompletedFolderText.legnth -1] === '/'){
          this.selectedCompletedFolderText = this.selectedCompletedFolderText.slice(0, this.selectedCompletedFolderText.length - 1)
        }
  
        if (this.isExternalStorage && this.selectedCompletedFolderText.toLowerCase() === this.newSelectedFolderText.toLowerCase())
          errorText += '- Please provide different UNC paths in COMPLETED FOLDER and IMPORT FROM - FOLDER.\n'
      }
      
      let importFromArray = this.importFromValues.map( e => e.name )
      if (!importFromArray.includes(this.newImportFromFolder.name)) {
        errorText += '- Please provide a value for "Import From."\n'
      } else {
        if (this.newSelectedFolder === '' && this.isVasionFolder) {
          errorText += '- Please provide a value for "Folder."\n'
        } else if (this.isExternalStorage) {
          if (!await this.$store.dispatch('automationEngine/getIsValidUncPath', { Value: this.newSelectedFolderText }))
            errorText += '- The value of "Folder" is not a valid UNC path.\n'
          if (this.moveToCompletedFolder && !await this.$store.dispatch('automationEngine/getIsValidUncPath', { Value: this.selectedCompletedFolderText}))
            errorText += '- The value of "Completed Folder" is not a valid UNC path.\n'
        }
      }

      for (let i = 0; i < this.reportEmails.length; i++){
        if (!(await this.$store.dispatch('common/validateEmail', this.reportEmails[i].value))){
          errorText += '- Please provide valid emails under Send Report Via Email section.\n'
          break
        }
      }

      if (this.deleteAppendOrUpdateRows === 'update') {
        const numberUniqueId = this.mappingConfiguration.filter(f => f.IsKey).length;
        if (numberUniqueId == 0) {
          errorText += 'At least one column must be marked as a unique identifier.\n'
        } else if (numberUniqueId == this.mappingConfiguration.length) {
          errorText += 'Every column cannot be marked as a unique identifier.\n'
        }
      } else {
        this.mappingConfiguration.forEach(field => field.IsKey = false)
      }
      
      if (errorText !== '') {
        this.snackbarSubTitle = errorText
        this.showSnackbarBool = true
        this.saveLocked = false
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .browse-button {
    padding-top: 15px;
  }

  .trash-can-padding {
    padding-top: 20px;
  }

  .row {
    width: 100%;
    margin: 13px 0;
    display: flex;

    .continue {
      margin : 0 0;
    }

    .subheader {
      @include SubHeadline;
    }

    .row-field {
      margin: 0 14px 5px 0;
    }

    .browse-input {
      margin-right: 5px;
    }

    .import-checkbox {
      width: 396px;
    }
  }

  .collapsable-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 225px;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
