<template>
  <div>
    <VasionGeneralModal
      id="configure-mapping-modal"
      modalType="slot"
      :sync="show"
      confirmButtonText="Save"
      rejectButtonText="Cancel"
      :showConfirmButton="!readOnly || allowUpdate"
      modalTitle="Mapping Configuration"
      showTopRightCloseButton
      @confirmButtonClick="setMappingConfiguration"
      @noButtonClick="closeMappingConfiguration"
    >
      <div id="configure-mapping-container">
        <div class="row fixed-table">
          <table id="mapping-config-table">
            <tr>
              <th class="first">
                Column Name
              </th>
              <th class="second">
                Map To
              </th>
              <th v-if="allowUpdate" class="key-column">
                Unique Identifier*
              </th>
              <th class="del-column">
              </th>
            </tr>
            <tr v-for="(row, index) in mappingList" :key="index">
              <td class="first">
                <VasionInput
                  v-model="row.TableFieldName"
                  class="row-field"
                  inputType="top-white"
                  name="csv-template"
                  placeholder="Column Name"
                  :width="'306'"
                  :readonly="readOnly"
                />
              </td>
              <td class="second">
                <VasionDropList
                  v-if="!readOnly"
                  v-slot="slotItem"
                  v-model="row.ImportFieldName"
                  class="row-field"
                  :dataArray="mappingConfigurationColumnsArray"
                  width="396"
                  placeholder="Select Mapping..."
                  type="plain-list"
                  displayName="name"
                  valueName="name"
                >
                  {{ slotItem.item.name }}
                </VasionDropList>
                <VasionInput
                  v-else
                  v-model="row.ImportFieldName"
                  class="row-field"
                  inputType="top-white"
                  name="csv-template"
                  placeholder="Column Name"
                  :width="'306'"
                  :readonly="true"
                />
              </td>
              
              <td v-if="allowUpdate" class="key-column">
                <VasionCheckbox
                  :id="(index + 1).toString()"  
                  class=""
                  :checked="row.IsKey"
                  @change="row.IsKey = !row.IsKey"
                />
              </td>
              <td class="del-column">
                <md-button v-if="!readOnly" class="md-icon-button md-dense" title="Remove Column" @click="deleteColumn(index)">
                  <VasionDeleteIcon />
                </md-button>
              </td>
            </tr>
          </table>
        </div>
        <div class="row">
          <VasionButton
            v-if="!readOnly"
            id="add-column"
            :classProp="'secondary-grey'"
            @vasionButtonClicked="addColumn()"
          >
            Add Column
          </VasionButton>  
          <span v-if="allowUpdate" class="identifier-rule">*Every column cannot be marked as a unique identifier.</span>
        </div>
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="import-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'AutomationCSVMapping',    
  props: {
    allowUpdate: {
      type: Boolean,
      required: true,
    },
    delimiter: {
      type: String,
      required: true,
    },
    readOnlyMapping: {
      type: Array,
      required: true,
    },
    show: {
       type: Boolean,
       required: true,
    },
    uploadedCSV: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mappingList: [],
      uploadedCSVFileString: '',
      mappingConfigurationColumnsArray: [],
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
     readOnly() { return this.uploadedCSV === '' }
  },
  watch: {
    async readOnlyMapping() {
      this.mappingList = this.readOnlyMapping
    },
    async uploadedCSV() {
      if (this.uploadedCSV === '') return

      this.uploadedCSVFileString = this.uploadedCSV

      const base64String = this.uploadedCSVFileString.split('base64,')[1]
      if (!base64String) {
        this.$emit('error', 'The CSV is empty')
        return
      }

      const csvString = atob(base64String)
      const csvRows = csvString.split('\r\n')
      const tempColumns = csvRows[0].split(this.delimiter)
      this.mappingConfigurationColumnsArray = tempColumns.map(col => {
        if (col.charAt(0) === '"' && col.charAt(col.length -1) === '"')
        {
          return { name: col.substr(1,col.length -2) }
        }
        return { name: col }
      })
      this.mappingList.push({ImportFieldName: '', TableFieldName: '', IsKey: false})
    },
  },
  methods: {
    addColumn() {
      this.mappingList.push({ImportFieldName: '', TableFieldName: '', IsKey: false})
    },
    closeMappingConfiguration() {
      this.$emit('close')
    },
    deleteColumn(index) {
      this.mappingList.splice(index, 1);
    },
    setMappingConfiguration() {
      const nonempty = this.mappingList.filter(v => v.TableFieldName)
      const uniqueValues = new Set(nonempty.map(v => v.TableFieldName))

      if (uniqueValues.size < nonempty.length) {
        this.snackbarTitle = "ERROR"
        this.snackbarSubTitle = 'Column names must be unique'
        this.showSnackbarBool = true
        return
      }
      
      if (this.allowUpdate) {
        //confirm at least one Unique Identifier, but not all
        const numberUniqueId = this.mappingList.filter(v => v.IsKey).length;
        if (numberUniqueId == 0) {
          this.snackbarTitle = "ERROR"
          this.snackbarSubTitle = 'At least one column must be marked as a unique identifier.'
          this.showSnackbarBool = true
          return
        }
        if (numberUniqueId == this.mappingList.length) {
          this.snackbarTitle = "ERROR"
          this.snackbarSubTitle = 'Every column cannot be marked as a unique identifier.'
          this.showSnackbarBool = true
          return
        }
      }

      const payload = this.mappingList.map(m => { 
        let importName = m.ImportFieldName?.name
        if (!importName) {
          importName = m.ImportFieldName
        }
        return {
          ImportFieldName: importName, 
          TableFieldName: m.TableFieldName,
          IsKey: m.IsKey,
        }
      })
      this.$emit('mappingConfiguration', payload)
      this.closeMappingConfiguration()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

#configure-mapping-container {
  display: flex;
  flex-wrap: wrap;
  width: 850px;
  height: 390px;
  max-width: 850px;
}

.fixed-table {
  height: 340px;
  overflow-y: auto;
}

#mapping-config-table td, #mapping-config-table th {
  padding: 0 0 0 8px;
}

#mapping-config-table {
  width: 100%;

  tr:first-child {
    border-bottom: solid 1px #CFD2D4;
  }
  tr:nth-child(even) {
    background: $grey-50;
  }
  tr {
    border-bottom: solid 1px #EBECEC;
    height: 56px;
  }

  .first {
    width: 40%;
  }
  .second {
    width: 40%;
  }
  .key-column {
    width: 10%;
    text-align: center;
  }
  .del-column {
    width: 5%;
  }
}

.identifier-rule {
  float: right;
  font-size: small;
}
.row {
  flex: 100%;
  margin: 0;
}
</style>